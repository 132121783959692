var Map = /** @class */ (function () {
    function Map(
    // map dimensions
    width, height) {
        this.width = width;
        this.height = height;
        // map texture
        this.image = null;
    }
    // creates a prodedural generated map
    Map.prototype.generate = function () {
        var ctx = document.createElement("canvas").getContext("2d");
        if (!ctx) {
            return;
        }
        ctx.canvas.width = this.width;
        ctx.canvas.height = this.height;
        this.drawGrid(ctx);
        // store the generate map as this image texture
        this.image = new Image();
        this.image.src = ctx.canvas.toDataURL("image/png");
        // clear context
        ctx = null;
    };
    Map.prototype.drawGrid = function (ctx, minor, major, stroke, fill) {
        if (minor === void 0) { minor = undefined; }
        if (major === void 0) { major = undefined; }
        if (stroke === void 0) { stroke = undefined; }
        if (fill === void 0) { fill = undefined; }
        minor = minor || 10;
        major = major || minor * 5;
        stroke = stroke || "#00FF00";
        fill = fill || "#009900";
        ctx.save();
        ctx.strokeStyle = stroke;
        ctx.fillStyle = fill;
        var width = ctx.canvas.width, height = ctx.canvas.height;
        for (var x = 0; x < width; x += minor) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.lineWidth = (x % major == 0) ? 0.5 : 0.25;
            ctx.stroke();
            // coordinate
            // if(x % major == 0 ) {
            //     ctx.fillText(x.toString(), x, 10);
            // }
        }
        for (var y = 0; y < height; y += minor) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            ctx.lineWidth = (y % major == 0) ? 0.5 : 0.25;
            ctx.stroke();
            // coordinate
            // if(y % major == 0 ) {
            //     ctx.fillText(y.toString(), 0, y + 10);
            // }
        }
        ctx.restore();
    };
    // draw the map adjusted to camera
    Map.prototype.draw = function (context, xView, yView) {
        if (!this.image) {
            return;
        }
        var sx, sy, dx, dy;
        var sWidth, sHeight, dWidth, dHeight;
        // offset point to crop the image
        sx = xView;
        sy = yView;
        // dimensions of cropped image			
        sWidth = context.canvas.width;
        sHeight = context.canvas.height;
        // if cropped image is smaller than canvas we need to change the source dimensions
        if (this.image.width - sx < sWidth) {
            sWidth = this.image.width - sx;
        }
        if (this.image.height - sy < sHeight) {
            sHeight = this.image.height - sy;
        }
        // location on canvas to draw the croped image
        dx = 0;
        dy = 0;
        // match destination with source to not scale the image
        dWidth = sWidth;
        dHeight = sHeight;
        context.drawImage(this.image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        // add current coordinate 
        var minor = 10;
        var major = minor * 5;
        var stroke = "#00FF00";
        var fill = "#009900";
        context.save();
        context.strokeStyle = stroke;
        context.fillStyle = fill;
        var width = context.canvas.width, height = context.canvas.height;
        for (var x = 0; x < width; x += minor) {
            if (x % major == 0) {
                context.fillText((~~(xView + x)).toString(), x, 10);
            }
        }
        for (var y = 0; y < height; y += minor) {
            if (y % major == 0) {
                context.fillText((~~(yView + y)).toString(), 0, y + 10);
            }
        }
        context.restore();
    };
    return Map;
}());
export { Map };
