import { Message } from "./proto/message_pb";
import { MoveCommand } from "./proto/move_command_pb";
import { Player } from './player';
import { Map as GameMap } from './map';
import { Camera } from "./camera";
import { ShipEnemy } from './proto/ship_enemy_pb';
import { Info } from './proto/info_pb';
import { GameInfo } from './gameInfo';
import { Control } from './control';
import { ShipPlayer } from './proto/ship_player_pb';
import { SpawnPlayer } from "./proto/spawn_player_pb";
import { MapData } from "./proto/map_data_pb";
import { GameDebug } from './gameDebug';
import { DebugData } from './proto/debug_data_pb';
var Point = /** @class */ (function () {
    function Point(x, y) {
        if (x === void 0) { x = 0; }
        if (y === void 0) { y = 0; }
        this.x = x;
        this.y = y;
    }
    return Point;
}());
export { Point };
;
export var Direction;
(function (Direction) {
    Direction[Direction["Stop"] = 0] = "Stop";
    Direction[Direction["SailUp"] = 1] = "SailUp";
    Direction[Direction["SailDown"] = 2] = "SailDown";
    Direction[Direction["SteeringLeft"] = 3] = "SteeringLeft";
    Direction[Direction["SteeringRight"] = 4] = "SteeringRight";
    Direction[Direction["None"] = 5] = "None";
})(Direction || (Direction = {}));
var Game = /** @class */ (function () {
    function Game(canvas, communication) {
        var _this = this;
        this.communication = communication;
        this.canvasWidth = 0; // width of the canvas
        this.canvasHeight = 0; // height of the canvas
        this.lastTime = 0;
        // private gameTime: number = 0;
        this.playDirection = Direction.Stop;
        this.previousPlayDirection = Direction.Stop;
        this.playerId = 0;
        this.players = new Map();
        this.infoEl = new GameInfo();
        this.debugEl = new GameDebug();
        // private serverDelta: number = 0.0;
        this.playerNearValue = 2000;
        this.width = 2000;
        this.height = 2000;
        this.requestAnimationFrame = function (callback) {
            return window.requestAnimationFrame(callback);
        };
        this.resizeCanvasToDisplaySize = function (canvas) {
            // look up the size the canvas is being displayed
            var width = canvas.clientWidth;
            var height = canvas.clientHeight;
            // If it's resolution does not match change it
            if (canvas.width !== width || canvas.height !== height) {
                canvas.width = width;
                canvas.height = height;
            }
        };
        this.ctx = canvas.getContext('2d');
        this.resizeCanvasToDisplaySize(this.ctx.canvas);
        this.control = new Control(this.ctx.canvas.width, this.ctx.canvas.height);
        // const width = 5000;
        // const height = 3000;
        this.map = new GameMap(this.width, this.height);
        this.map.generate();
        // Set the right viewport size for the camera
        var vWidth = Math.min(this.width, canvas.width);
        var vHeight = Math.min(this.height, canvas.height);
        // Setup the camera
        this.camera = new Camera(0, 0, vWidth, vHeight, this.width, this.height);
        // Setup the control
        var that = this;
        var interval = [];
        var interval2 = [];
        window.addEventListener('keydown', function (event) {
            if (event.repeat) {
                return;
            }
            var playDirection = that.control.getDirectionBykeyCode(event.keyCode);
            interval.push(window.setInterval(function () {
                if (playDirection !== Direction.None) {
                    that.playDirection = playDirection;
                }
            }, 1000 / 10));
        }, false);
        window.addEventListener('keyup', function () {
            while (interval.length !== 0) {
                window.clearInterval(interval.pop());
            }
        }, false);
        var elemLeft = canvas.offsetLeft, elemTop = canvas.offsetTop;
        // desktop
        canvas.addEventListener('mousedown', function (event) {
            var x = event.pageX - elemLeft;
            var y = event.pageY - elemTop;
            var playDirection = that.control.getDirectionByPoint(new Point(x, y));
            interval2.push(window.setInterval(function () {
                if (playDirection !== Direction.None) {
                    that.playDirection = playDirection;
                }
            }, 1000 / 10));
        }, false);
        canvas.addEventListener('mouseup', function () {
            while (interval2.length !== 0) {
                window.clearInterval(interval2.pop());
            }
        }, false);
        // mobile
        canvas.addEventListener('touchstart', function (event) {
            var x = event.changedTouches[0].screenX - elemLeft;
            var y = event.changedTouches[0].screenY - elemTop;
            var playDirection = that.control.getDirectionByPoint(new Point(x, y));
            interval2.push(window.setInterval(function () {
                if (playDirection !== Direction.None) {
                    that.playDirection = playDirection;
                }
            }, 1000 / 10));
        }, false);
        canvas.addEventListener('touchend', function () {
            while (interval2.length !== 0) {
                window.clearInterval(interval2.pop());
            }
        }, false);
        // Processing message
        this.communication.onMesssage(function (msg) {
            if (msg.getType() === Message.Type.JOINED && !_this.players.has(msg.getPlayerid())) {
                var player = new Player(msg.getPlayerid(), 50, 50);
                player.setDirection(Direction.None);
                _this.players.set(msg.getPlayerid(), player);
                _this.playerId = msg.getPlayerid();
                _this.camera.follow(player, vWidth / 2, vHeight / 2);
            }
            else if (msg.getType() === Message.Type.INFO) {
                var info = Info.deserializeBinary(msg.getData_asU8());
                _this.infoEl.setInfo(info);
            }
            else if (msg.getType() === Message.Type.DEBUGDATA) {
                var debugData = DebugData.deserializeBinary(msg.getData_asU8());
                _this.debugEl.setDebugData(debugData);
            }
            else if (msg.getType() === Message.Type.QUIT) {
                if (_this.players.has(msg.getPlayerid())) {
                    _this.players.delete(msg.getPlayerid());
                }
            }
            else if (msg.getType() === Message.Type.SPAWNPLAYER) {
                var data = SpawnPlayer.deserializeBinary(msg.getData_asU8());
                var player = new Player(msg.getPlayerid(), data.getX(), data.getY(), 200);
                _this.players.set(msg.getPlayerid(), player);
            }
            else if (msg.getType() === Message.Type.SHIPPLAYER) {
                var data = ShipPlayer.deserializeBinary(msg.getData_asU8());
                if (_this.players.has(msg.getPlayerid())) {
                    var player = _this.players.get(msg.getPlayerid());
                    player.applyData(data);
                }
            }
            else if (msg.getType() === Message.Type.SHIPENEMY) {
                var data = ShipEnemy.deserializeBinary(msg.getData_asU8());
                if (_this.players.has(msg.getPlayerid())) {
                    var player = _this.players.get(msg.getPlayerid());
                    player.applyData(data);
                }
            }
            else if (msg.getType() === Message.Type.MAPDATA) {
                var data = MapData.deserializeBinary(msg.getData_asU8());
                for (var i = 0; i < data.getEnemiesList().length; i++) {
                    var enemy = data.getEnemiesList()[i];
                    if (_this.players.has(enemy.getId())) {
                        var player = _this.players.get(enemy.getId());
                        player.applyData(enemy);
                    }
                }
            }
        });
    }
    Game.prototype.start = function () {
        var _this = this;
        var now = Date.now();
        var dt = (now - this.lastTime) / 1000.0;
        this.update(dt);
        this.render();
        this.lastTime = now;
        this.requestAnimationFrame(function () { return _this.start(); });
    };
    Game.prototype.update = function (dt) {
        // this.gameTime += dt;
        this.handleInput(dt);
        this.updateEntities(dt);
    };
    Game.prototype.handleInput = function (dt) {
        // pass if the same direction
        if (this.playDirection === Direction.None) {
            return;
        }
        if (this.players.has(this.playerId)) {
            this.players.get(this.playerId).setDirection(this.playDirection);
        }
        // send to server
        var message = new Message();
        message.setType(Message.Type.MOVECOMMAND);
        var direction = new MoveCommand();
        // let xv: number = 0;
        // let yv: number = 0;
        switch (this.playDirection) {
            case Direction.SailDown:
                // yv = 1;
                // xv = 0;
                direction.setSails(MoveCommand.Sails.LESS);
                break;
            case Direction.SailUp:
                // yv = -1;
                // xv = 0;
                direction.setSails(MoveCommand.Sails.MORE);
                break;
            case Direction.SteeringLeft:
                // yv = 0;
                // xv = -1;
                direction.setSteering(MoveCommand.Steering.LEFT);
                break;
            case Direction.SteeringRight:
                // yv = 0;
                // xv = 1;
                direction.setSteering(MoveCommand.Steering.RIGHT);
                break;
        }
        // if (yv != 0 || xv != 0) {
        //   this.players.get(this.playerId)!.setVelocity(yv, xv)
        // }
        // this.players.get(this.playerId)!.setKey(yv, xv)
        // console.log(new Date().getTime(), "new Date().getTime()");
        direction.setId(new Date().getTime());
        // direction.setXv(xv);
        // direction.setYv(yv);
        message.setData(direction.serializeBinary());
        this.communication.sendMessage(message);
        // end
        if (this.players.has(this.playerId)) {
            var player = this.players.get(this.playerId);
            // player.setVelocity(yv, xv);
            player.update(dt, this.width, this.height);
        }
        // if (this.players.has(this.playerId)) {
        //   const player = this.players.get(this.playerId)!
        //   player.addCommand(direction);
        // }
        // this.previousPlayDirection = this.playDirection;
        this.playDirection = Direction.None;
    };
    Game.prototype.updateEntities = function (dt) {
        var _this = this;
        // Update the player sprite animation
        this.players.forEach(function (player) {
            // player.interpolate(this.serverDelta)
            player.update(dt, _this.width, _this.height);
            // Collision check
            _this.players.forEach(function (otherPlayer) {
                if (player.id != otherPlayer.id
                    && player.isNear(otherPlayer, _this.playerNearValue)
                    && player.overlaps(otherPlayer)) {
                    player.revertDirection();
                    otherPlayer.revertDirection();
                }
            });
        });
        this.camera.update();
    };
    Game.prototype.render = function () {
        var _this = this;
        this.canvasWidth = this.ctx.canvas.width;
        this.canvasHeight = this.ctx.canvas.height;
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.map.draw(this.ctx, this.camera.xView, this.camera.yView);
        this.infoEl.render(this.ctx, this.canvasWidth, this.canvasHeight);
        // debug info
        this.debugEl.render(this.ctx, this.canvasWidth, this.canvasHeight);
        this.players.forEach(function (player) {
            player.render(_this.ctx, _this.camera.xView, _this.camera.yView);
        });
    };
    return Game;
}());
export { Game };
