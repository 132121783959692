import './style/normalize.css';
import './style/style.css';
import { Message } from "./proto/message_pb";
import { Game } from './game';
import { Сommunication } from './communication';
var App = /** @class */ (function () {
    function App(canvas) {
        this.canvas = canvas;
        this.communication = new Сommunication();
    }
    App.prototype.start = function () {
        var _this = this;
        setTimeout(function () {
            new Game(_this.canvas, _this.communication).start();
        }, 500);
        // join to game
        this.communication.onConnect(function () {
            setTimeout(function () {
                var message = new Message();
                message.setType(Message.Type.JOIN);
                _this.communication.sendMessage(message);
            }, 1000);
        });
        this.communication.setup();
    };
    return App;
}());
var gameCanvas = document.getElementById('game');
new App(gameCanvas).start();
