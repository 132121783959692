var GameInfo = /** @class */ (function () {
    function GameInfo(x, y, color) {
        if (x === void 0) { x = 100; }
        if (y === void 0) { y = 100; }
        if (color === void 0) { color = '#ffffff00'; }
        this.x = x;
        this.y = y;
        this.color = color;
    }
    GameInfo.prototype.setInfo = function (info) {
        this.info = info;
    };
    GameInfo.prototype.render = function (ctx, w, h) {
        if (!this.info) {
            return;
        }
        var count = this.info.getCount();
        var players = this.info.getPlayersList();
        ctx.fillStyle = 'rgba(251, 251, 251, 0.3)';
        ctx.fillRect(w - 85, 15, 80, 100);
        // display scores
        this.text(ctx, 'Players: ' + count, w - 80, 30, 14, 'rgba(251, 251, 251, 0.76)');
        for (var i = 0; i < players.length; ++i) {
            var player = players[i];
            var offset = i == 0 ? 15 : (i + 1) * 15;
            this.text(ctx, '#' + (i + 1) + ": " + player.getId() + " - " + player.getScore(), w - 80, 30 + offset, 14, 'rgba(251, 251, 251, 0.76)');
        }
    };
    GameInfo.prototype.text = function (ctx, text, x, y, size, col) {
        ctx.font = 'bold ' + size + 'px';
        ctx.fillStyle = col;
        ctx.fillText(text, x, y);
    };
    return GameInfo;
}());
export { GameInfo };
