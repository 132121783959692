import { Direction } from './game';
var TO_RADIANS = Math.PI / 180;
var Player = /** @class */ (function () {
    function Player(id, x, y, speed, 
    // private readonly radius = 0,
    color) {
        if (x === void 0) { x = 100; }
        if (y === void 0) { y = 100; }
        if (speed === void 0) { speed = 50; }
        if (color === void 0) { color = '#ffffff00'; }
        this.id = id;
        this.x = x;
        this.y = y;
        this.speed = speed;
        this.color = color;
        this.v = 0;
        // private vy = 0;
        this.direction = Direction.Stop;
        this.commands = [];
        this.dataBuffer = [];
        this.w = 35;
        this.h = 100;
        this.name = "";
        // new controll
        this.sails = 0;
        this.steering = 0;
        this.angleV = 0;
        this.angle = 0;
        this.sailsMax = 3;
        this.speedMax = 10.0;
        this.speedAcceleration = 1.0;
        this.speedDrag = 0.2;
        this.steeringMax = 4.0;
        this.steeringMin = -4.0;
        this.steeringAcceleration = 2.0;
        this.steeringDrag = 1.0;
        // texture
        this.image = null;
    }
    Player.prototype.update = function (dt, worldWidth, worldHeight) {
        // parameter step is the time between frames ( in seconds )
        // if (this.vy != 0) {
        //   this.y = this.y + (dt *this.speed * this.vy);
        // }
        // if (this.vx != 0) {
        //   this.x = this.x + (dt *this.speed * this.vx);
        // }
        // // don't let player leaves the world's boundary
        // if (this.y + this.h/2 > worldHeight || this.y - this.h/2 < 0) {
        //   this.vy = (this.y + this.h/2) > worldHeight ? -1 : 1;
        // }
        // if (this.x + this.w/2 > worldWidth || this.x - this.w/2 < 0) {
        //   this.vx = (this.x + this.w/2) > worldWidth ? -1 : 1;
        // }
    };
    Player.prototype.applyData = function (data) {
        this.setPosition(data.getY(), data.getX());
        this.setVelocity(data.getV());
        this.setSails(data.getSails());
        this.setAngle(data.getAngle());
        this.setAngleV(data.getAngleV());
        this.setName("#" + data.getId().toString());
    };
    // reconciliation
    Player.prototype.addMoveCommand = function (command) {
        this.commands.push(command);
    };
    Player.prototype.reconciliation = function (data) {
        // Received the authoritative position of this player.
        // this.applyData(data);
        // this.y = data.getY()
        // this.x = data.getX()
        if (this.commands.length === 0) {
            this.applyData(data);
        }
        else if (data.getId() !== 0) {
            var j = 0;
            while (j < this.commands.length) {
                var command = this.commands[j];
                if (command.getId() <= data.getId()) {
                    // Already processed. Its effect is already taken into account into the world update
                    // we just got, so we can drop it.
                    this.commands.splice(j, 1);
                }
                else {
                    // Not processed by the server yet. Re-apply it.
                    this.applyData(data);
                    j++;
                }
            }
        }
    };
    Player.prototype.interpolate = function (dt) {
        // Compute render timestamp.
        var now = +new Date();
        var render_timestamp = now - (1000 * dt);
        // console.log((dt))
        // if (this.dataBuffer.length >= 2){
        //   console.log(this.dataBuffer[1][0] <= render_timestamp)
        // }
        // Drop older positions.
        while (this.dataBuffer.length >= 2 && this.dataBuffer[1][0] <= render_timestamp) {
            this.dataBuffer.shift();
        }
        // console.log(this.dataBuffer.length);
        // Interpolate between the two surrounding authoritative positions.
        if (this.dataBuffer.length >= 2 && this.dataBuffer[0][0] <= render_timestamp && render_timestamp <= this.dataBuffer[1][0]) {
            var p0 = this.dataBuffer[0][1];
            var p1 = this.dataBuffer[1][1];
            var t0 = this.dataBuffer[0][0];
            var t1 = this.dataBuffer[1][0];
            // const x = p0.getX() + (p1.getX() - p0.getX()) * (render_timestamp - t0) / (t1 - t0);
            // const y = p0.getY() + (p1.getY() - p0.getY()) * (render_timestamp - t0) / (t1 - t0);
            // this.x = this.x + (dt *this.speed * this.vx);
            var x = p0.getX() + (p1.getX() - p0.getX()) * (render_timestamp - t0) / (t1 - t0);
            var y = p0.getY() + (p1.getY() - p0.getY()) * (render_timestamp - t0) / (t1 - t0);
            // this.applyData(this.dataBuffer[0][1])
            //console.log(x, y);
            this.setPosition(y, x);
            // this.setVelocity(p0.getYv(), p0.getXv())
        }
    };
    Player.prototype.addData = function (data) {
        var timestamp = +new Date();
        this.dataBuffer.push([timestamp, data]);
    };
    Player.prototype.setColor = function (color) {
        this.color = color;
    };
    Player.prototype.setDirection = function (direction) {
        this.direction = direction;
    };
    Player.prototype.setPosition = function (y, x) {
        this.y = y;
        this.x = x;
    };
    Player.prototype.setVelocity = function (v) {
        this.v = v;
    };
    Player.prototype.setSails = function (s) {
        this.sails = s;
    };
    Player.prototype.setAngle = function (a) {
        this.angle = a;
    };
    Player.prototype.setAngleV = function (v) {
        this.angleV = v;
    };
    Player.prototype.setName = function (v) {
        this.name = v;
    };
    Player.prototype.render = function (ctx, xView, yView) {
        // ctx.beginPath();
        // ctx.arc(
        //   this.x - xView,
        //   this.y - yView,
        //   this.radius,
        //   0,
        //   Math.PI * 2,
        //   true
        // );
        // ctx.closePath();
        // // Colors and fills the ball
        // ctx.fillStyle = this.color;
        // ctx.fill();
        if (!this.image) {
            this.loadImage("ship.png");
        }
        else {
            // ctx.drawImage(this.image, this.x - xView-this.w/2, this.y - yView-this.h/2, this.w, this.h);
            this.rotateAndPaintImage(ctx, this.image, (this.angleV * TO_RADIANS) + 1.5, this.x - xView, this.y - yView, this.image.width / 6, this.image.height / 6);
            ctx.fillStyle = 'rgba(251, 251, 251, 0.4)';
            ctx.fillRect(this.x - xView - 25, this.y - yView, 50, 1);
            ctx.fillText("x", this.x - xView - 25, this.y - yView - 3);
            ctx.fillRect(this.x - xView, this.y - yView - 25, 1, 50);
            ctx.fillText("y", this.x - xView + 3, this.y - yView - 20);
            ctx.fillText(this.name, this.x - xView + 10, this.y - yView - 3);
        }
    };
    Player.prototype.rotateAndPaintImage = function (context, image, angleInRad, positionX, positionY, axisX, axisY) {
        context.translate(positionX, positionY);
        context.rotate(angleInRad);
        context.drawImage(image, -axisX, -axisY, image.width / 3, image.height / 3);
        context.rotate(-angleInRad);
        context.translate(-positionX, -positionY);
    };
    Player.prototype.loadImage = function (url) {
        var img = new Image();
        img.src = url;
        var that = this;
        img.onload = function () {
            that.image = img;
        };
    };
    // Determines is a player is near other player.
    Player.prototype.isNear = function (other, playerNearValue) {
        var xdiff = Math.abs(this.x - other.x);
        var ydiff = Math.abs(this.y - other.y);
        var mdiff = Math.max(xdiff, ydiff);
        return mdiff < playerNearValue;
    };
    Player.prototype.overlaps = function (other) {
        var dx = this.x - other.x;
        var dy = this.y - other.y;
        var distance = Math.sqrt(dx * dx + dy * dy);
        return distance < this.w + other.w;
    };
    Player.prototype.revertDirection = function () {
        // this.setVelocity(-this.vy, -this.vx);
    };
    return Player;
}());
export { Player };
