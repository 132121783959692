var GameDebug = /** @class */ (function () {
    function GameDebug(x, y) {
        if (x === void 0) { x = 100; }
        if (y === void 0) { y = 100; }
        this.x = x;
        this.y = y;
    }
    GameDebug.prototype.setDebugData = function (debugData) {
        this.debugData = debugData;
    };
    GameDebug.prototype.render = function (ctx, w, h) {
        if (!this.debugData) {
            return;
        }
        ctx.fillStyle = 'rgba(251, 251, 251, 0.3)';
        ctx.fillRect(20, 15, 150, 365);
        var row = 0;
        this.text(ctx, '- Debug', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, '-- player', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'steering direction: ' + this.debugData.getSteeringdirection().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'sails current: ' + this.debugData.getSailscurrent().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'steering: ' + this.debugData.getSteering().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'speed d: ' + this.debugData.getSpeedd().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'speed: ' + this.debugData.getSpeed().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'angle d: ' + this.debugData.getAngled().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, 'angle: ' + this.debugData.getAngle().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        this.text(ctx, '', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        var movecommand = this.debugData.getLastmovecommand();
        if (movecommand) {
            this.text(ctx, '-- move command', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'sails: ' + movecommand.getSails().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'steering: ' + movecommand.getSteering().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, '', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        }
        var shipplayer = this.debugData.getShipplayer();
        if (shipplayer) {
            this.text(ctx, '-- ship player', 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'tick: ' + shipplayer.getTick().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'hp: ' + shipplayer.getHp().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'sails: ' + shipplayer.getSails().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'x: ' + shipplayer.getX().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'y: ' + shipplayer.getY().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'angle: ' + shipplayer.getAngle().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'v: ' + shipplayer.getV().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'angle_v: ' + shipplayer.getA().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
            this.text(ctx, 'angle_a: ' + shipplayer.getAngleA().toString(), 25, 30 + (15 * row++), 14, 'rgba(251, 251, 251, 0.76)');
        }
    };
    GameDebug.prototype.text = function (ctx, text, x, y, size, col) {
        ctx.font = 'bold ' + size + 'px';
        ctx.fillStyle = col;
        ctx.fillText(text, x, y);
    };
    return GameDebug;
}());
export { GameDebug };
