var Rectangle = /** @class */ (function () {
    function Rectangle(left, top, width, height) {
        if (left === void 0) { left = 0; }
        if (top === void 0) { top = 0; }
        if (width === void 0) { width = 0; }
        if (height === void 0) { height = 0; }
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
        this.right = this.left + this.width;
        this.bottom = this.top + this.height;
    }
    Rectangle.prototype.set = function (left, top, width, height) {
        if (width === void 0) { width = undefined; }
        if (height === void 0) { height = undefined; }
        this.left = left;
        this.top = top;
        this.width = width || this.width;
        this.height = height || this.height;
        this.right = (this.left + this.width);
        this.bottom = (this.top + this.height);
    };
    Rectangle.prototype.within = function (r) {
        return (r.left <= this.left &&
            r.right >= this.right &&
            r.top <= this.top &&
            r.bottom >= this.bottom);
    };
    Rectangle.prototype.overlaps = function (r) {
        return (this.left < r.right &&
            r.left < this.right &&
            this.top < r.bottom &&
            r.top < this.bottom);
    };
    Rectangle.prototype.getTop = function () {
        return this.top;
    };
    Rectangle.prototype.getBottom = function () {
        return this.bottom;
    };
    Rectangle.prototype.getLeft = function () {
        return this.left;
    };
    Rectangle.prototype.getRight = function () {
        return this.right;
    };
    return Rectangle;
}());
export { Rectangle };
