import { Rectangle } from './rectangle';
export var Axis;
(function (Axis) {
    Axis[Axis["NONE"] = 1] = "NONE";
    Axis[Axis["HORIZONTAL"] = 2] = "HORIZONTAL";
    Axis[Axis["VERTICAL"] = 3] = "VERTICAL";
    Axis[Axis["BOTH"] = 4] = "BOTH";
})(Axis || (Axis = {}));
var Camera = /** @class */ (function () {
    function Camera(
    // position of camera (left-top coordinate)
    xView, yView, viewportWidth, viewportHeight, worldWidth, worldHeight) {
        if (xView === void 0) { xView = 0; }
        if (yView === void 0) { yView = 0; }
        this.xView = xView;
        this.yView = yView;
        // distance from followed object to border before camera starts move
        this.xDeadZone = 0; // min distance to horizontal borders
        this.yDeadZone = 0; // min distance to vertical borders
        // allow camera to move in vertical and horizontal axis
        this.axis = Axis.BOTH;
        // object that should be followed
        this.followed = null;
        // viewport dimensions
        this.wView = viewportWidth;
        this.hView = viewportHeight;
        // rectangle that represents the viewport
        this.viewportRect = new Rectangle(this.xView, this.yView, this.wView, this.hView);
        // rectangle that represents the world's boundary (room's boundary)
        this.worldRect = new Rectangle(0, 0, worldWidth, worldHeight);
    }
    Camera.prototype.follow = function (gameObject, xDeadZone, yDeadZone) {
        this.followed = gameObject;
        this.xDeadZone = xDeadZone;
        this.yDeadZone = yDeadZone;
    };
    Camera.prototype.update = function () {
        // keep following the player (or other desired object)
        if (this.followed != null) {
            if (this.axis == Axis.HORIZONTAL || this.axis == Axis.BOTH) {
                // moves camera on horizontal axis based on followed object position
                if (this.followed.x - this.xView + this.xDeadZone > this.wView)
                    this.xView = this.followed.x - (this.wView - this.xDeadZone);
                else if (this.followed.x - this.xDeadZone < this.xView)
                    this.xView = this.followed.x - this.xDeadZone;
            }
            if (this.axis == Axis.VERTICAL || this.axis == Axis.BOTH) {
                // moves camera on vertical axis based on followed object position
                if (this.followed.y - this.yView + this.yDeadZone > this.hView)
                    this.yView = this.followed.y - (this.hView - this.yDeadZone);
                else if (this.followed.y - this.yDeadZone < this.yView)
                    this.yView = this.followed.y - this.yDeadZone;
            }
        }
        // update viewportRect
        this.viewportRect.set(this.xView, this.yView);
        // don't let camera leaves the world's boundary
        if (!this.viewportRect.within(this.worldRect)) {
            if (this.viewportRect.getLeft() < this.worldRect.getLeft())
                this.xView = this.worldRect.getLeft();
            if (this.viewportRect.getTop() < this.worldRect.getTop())
                this.yView = this.worldRect.getTop();
            if (this.viewportRect.getRight() > this.worldRect.getRight())
                this.xView = this.worldRect.getRight() - this.wView;
            if (this.viewportRect.getBottom() > this.worldRect.getBottom())
                this.yView = this.worldRect.getBottom() - this.hView;
        }
    };
    return Camera;
}());
export { Camera };
